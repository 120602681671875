import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import github from './content/images/icons/github.png'
import linkedin from './content/images/icons/linkedin.png'
import xing from './content/images/icons/xing.png'
import arrow from './content/images/icons/arrow.png'
import '../stylesheet.css'
import './navbar.css'


function Navbar() {

    const [click, setClick] = useState(false)
    const [isMobile, setIsMobile] = useState(false)
    
    const closeMobileMenu = () => setClick(false)
    const handleClick = () => {setClick(!click)}

    const handleResize = () =>{
        if (window.innerWidth <= 960){
            setIsMobile(true)
        } else {
            setIsMobile(false)
            setClick(false)
        }
    }

    useEffect(() => {
        handleResize()
    }, [])

    window.addEventListener("resize", handleResize)

    return (
        <>
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                    <h1 className="logo">{"</JensHinger>"}</h1>
                </Link>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>

                    {isMobile ?
                        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                            <h1 className="logo">{"</JensHinger>"}</h1>
                        </Link>
                        : 
                        <div/>
                    }
                    
                    <li className="nav-item">
                        <Link to='/' className="nav-links" onClick={closeMobileMenu}>
                            Über mich
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/projects' className="nav-links" onClick={closeMobileMenu}>
                            Projekte
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to='/contact' className="nav-links" onClick={closeMobileMenu}>
                            Kontakt
                        </Link>
                    </li>
                    <li className={isMobile ? "nav-item-mobile":"nav-item"}>
                        <Link className="media_link"  to={{pathname : "https://github.com/JensHinger"}} target="_blank" rel="noopener">
                            <img src={github} alt="Github" className="icon"/>
                        </Link>
                    </li>
                    <li className={isMobile ? "nav-item-mobile":"nav-item"}>
                        <Link className="media_link" to={{pathname : "https://de.linkedin.com/in/jens-hinger-4a2135201"}} target="_blank" rel="noopener">
                            <img src={linkedin} alt="LinkedIn" className="icon"/>
                        </Link>
                    </li>
                    <li className={isMobile ? "nav-item-mobile":"nav-item"}>
                        <Link className="media_link" to={{pathname : "https://www.xing.com/profile/Jens_Hinger"}} target="_blank" rel="noopener">
                            <img src={xing} alt="Xing" className="icon"/>
                        </Link>
                    </li>
                </ul>
                <div className={click ? 'menu-icon active' : 'menu-icon'} onClick={handleClick}>
                    {click ? <img src={arrow} alt="Menü offen" className="mobile-icon"/> : <img src={arrow} style={{transform: "rotate(180deg)"}} alt="Menü zu" className="mobile-icon"/>}
                </div>
            </div>
        </nav>  
    </>
    )
}

export default Navbar
