import './stylesheet.css';
import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer'
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Projects from './components/pages/Projects';
import Impressum from './components/pages/Impressum';
import Datasecurity from './components/pages/Datasecurity';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        
        <Switch>
          <Route path="/" exact component={Home}/>
        </Switch>
        <Switch>
          <Route path="/contact" exact component={Contact}/>
        </Switch>
        <Switch>
          <Route path="/projects" exact component={Projects}/>
        </Switch>
        <Switch>
          <Route path="/impressum" exact component={Impressum}/>
        </Switch>
        <Switch>
          <Route path="/datasecurity" exact component={Datasecurity}/>
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;
