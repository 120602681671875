import React, { Component } from 'react'
import ProjectCard from '../ProjectCard'
import '../../stylesheet.css'
import './project.css'

class Projects extends Component {

    constructor(props){
        super(props);
        this.state = {
            projects : require('../content/projects.json'),
        }
    }

    render(){
        return (
            <div className="projects-container">
                {this.state.projects.map((project) => <ProjectCard key={project['project-title']} projectTitle={project['project-title']} projectDescription={project['project-description']} projectLink={project['project-link']} projectBackground={project['project-background']}/>)}
            </div>
        )
    
    }   
}

export default Projects
