import React, {Component, useState} from 'react'
import cross from './content/images/icons/cross.png'
import '../stylesheet.css'
import './InfoMessage.css'

class InfoMessage extends Component{

    render(){

        return (
            <div className="infobox" 
            style={{
                backgroundColor: this.props.condition ? "#43a047" : "#e53935"
            }}>
                <h2 className="infomessage">{this.props.message}</h2>
                <div className="infobutton">
                    <img src={cross} onClick={this.props.resetInfoBox} alt="Info schließen"/>
                </div>
            </div>
        )
    } 
}

export default InfoMessage
