import React from 'react'
import { Link } from 'react-router-dom'
import '../stylesheet.css'
import './footer.css'

function Footer() {
    return (
        <div className="footer-container">
            <div className="footer-links">
                <Link className="text-links" to='/impressum'>
                    Impressum
                </Link>
                &nbsp;|&nbsp;
                <Link className="text-links" to='/datasecurity'>
                    Datenschutzerklärung
                </Link>
            </div>
            <div className="freepik">
                Icons erstellt von 
                <a className="text-links" href="https://www.freepik.com" title="Freepik" target="_blank" rel="noopener"> Freepik </a>
                from 
                <a className="text-links" href="https://www.flaticon.com/de/" title="Flaticon" target="_blank" rel="noopener"> www.flaticon.com</a>
            </div>
        </div>
    )
}

export default Footer
