import React from 'react'
import person from '../content/images/contentImages/profile_picture.jpg'
import aiIcon from '../content/images/icons/ai.png'
import statisticsIcon from '../content/images/icons/statistics.png'
import codeIcon from '../content/images/icons/code.png'
import '../../stylesheet.css'
import './home.css'

function home() {
    return (
        <div className="home_container">
            <div className="left_home">
                <img src={person} alt="Couldn't load" className="person"/>
                <div className="interests_container">
                    <h2 className="interests">Interessen</h2>
                    <ul className="interest_list">
                        <p className="interest_bulletpoint">
                            <img src={codeIcon} alt="" className="interest_icon"/> 
                            Programming
                        </p>
                        <p className="interest_bulletpoint">
                            <img src={aiIcon} alt="" className="interest_icon"/>
                            Machine Learning
                        </p>
                        <p className="interest_bulletpoint">
                            <img src={statisticsIcon} alt="" className="interest_icon"/> 
                            Data Science
                        </p>
                    </ul>
                </div>
            </div>
            <div className="right_home">
                <div className="text_container">
                    <h2 className="headlines">
                        Über mich
                    </h2>
                    <div className="text">
                        <p>
                            Willkommen auf der persönlichen Webseite von Jens Hinger. Ich studiere derzeit Wirtschaftsinformatik 
                            und digitale Medien an der Hochschule der Medien in Stuttgart. 
                        </p>
                        <p>
                            Auf dieser Webseite finden Sie Informationen über Projekte, welche ich in meiner Freizeit
                            oder während den letzten Semestern meines Studiums angegangen bin. 
                        </p>
                        <p>
                            Das Hauptziel dieser Webseite ist es meine Projekte ausführlich und übersichtlich zu dokumentieren.
                        </p>
                    </div>
                </div>
                <div className="text_container">
                    <h2 className="headlines">
                        Meine Kenntnisse
                    </h2>
                    <div className="text">
                        <p>
                            Als Wirtschaftsinformatiker konnte ich schon viel Erfahrung in den unterschiedlichsten Bereichen sammeln.
                            Von BPMN, SQL über User Acceptance Test bis hinzu Data Science. Näheres können Sie auf meinem LinkedIn Profil erfahren.
                        </p>
                        <p>
                            Derzeitig versuche ich mich in dem Bereich Machine Learning weiterzuentwickeln. 
                            Des Weiteren werde ich in dem nächsten Semester mich mit Unity, C# sowie Blender 
                            befassen. Hierfür werde Ich eine Applikation für die Microsoft HoloLens 2 entwickeln.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default home
