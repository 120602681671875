import React from 'react'
import './impressum.css'
import '../../stylesheet.css'

export default function Impressum() {
    return (
        <div className="impressum-container">
            <div className="impressum-text">
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>
                    Jens Hinger
                    <br />
                    Hammerst&auml;ttle 6
                    <br />
                    72160 Horb am Neckar
                </p>

                <h2>Kontakt</h2>

                <p>
                    Telefon: 0176 85625679
                    <br />
                    E-Mail: hingerjenswebsite@gmail.com
                </p>
            </div>
        </div>
    )
}
