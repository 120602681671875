import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../stylesheet.css'
import './projectCard.css'

class ProjectCard extends Component {

    render(){
        return (
            <div className="project-box" style={{backgroundImage : `url(${this.props.projectBackground})`}}>
                {this.props.projectLink === "" ? 
                <div className="project-more-info">
                    <h2 className="project-title">
                        {this.props.projectTitle}
                    </h2>
                    <div className="project-description">
                        {this.props.projectDescription}
                    </div>
                    <div className="project-instruction">
                        {this.props.projectLink === "" ? "" : "Klicken um mehr zu erfahren..."}
                        
                    </div>
                </div>
                
                :
                    
                <Link className="project-more-info" to={{pathname : this.props.projectLink}} target="_blank" ref="noreferrer">
                    <h2 className="project-title">
                        {this.props.projectTitle}
                    </h2>
                    <div className="project-description">
                        {this.props.projectDescription}
                    </div>
                    <div className="project-instruction">
                        Klicken um mehr zu erfahren...
                        
                    </div>
                </Link>
                }
            </div>
        )
    }
}

export default ProjectCard
