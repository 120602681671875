import React, { Component } from 'react'
import '../../stylesheet.css'
import './contact.css'
import InfoMessage from '../InfoMessage.js'

class Contact extends Component {

    constructor(props){
        super(props);
        this.state={
            formName: "",
            formEmail: "",
            formRegard: "",
            formMessage: "",
            validEmail: false,
            acceptDataSecurity: false,
            displayInfo: false,
            displayInfoMessage: "",
            displayInfoCondition: false,
        }
        this.baseState = this.state 
    }

    resetInfoBox = () => {  
        this.setState({displayInfo : false})
    }

    render(){

        const checkName = () => {
            if(this.state.formName.length > 1){
                return true
            } else {
                return false
            }
        }

        const checkRegard = () => {
            if(this.state.formRegard.length > 1){
                return true
            } else {
                return false
            }
        }

        const checkMessage = () => {
            if(this.state.formMessage.length > 1){
                return true
            } else {
                return false
            }
        }

        const checkAll = () => {
            if(checkName() && checkRegard() && checkMessage() && this.state.validEmail && this.state.acceptDataSecurity){
                return true
            } else {
                // InfoMessages überprüfen und überarbeiten!
                if(!checkName()){
                    this.setState({
                        displayInfo: true,
                        displayInfoMessage: "Bitte geben Sie einen korrekten Namen ein!",
                        displayInfoCondition: false
                    })
                } else if(!this.state.validEmail){
                    this.setState({
                        displayInfo: true,
                        displayInfoMessage: "Bitte geben Sie eine korrekte Email ein!",
                        displayInfoCondition: false
                    })
                } else if(!checkRegard()){
                    this.setState({
                        displayInfo: true,
                        displayInfoMessage: "Bitte geben Sie einen korrekten Betreff ein!",
                        displayInfoCondition: false
                    })
                } else if(!checkMessage()){
                    this.setState({
                        displayInfo: true,
                        displayInfoMessage: "Bitte geben Sie eine korrekte Nachricht ein!",
                        displayInfoCondition: false
                    })
                
                } else if(!this.state.acceptDataSecurity){
                    this.setState({
                        displayInfo: true,
                        displayInfoMessage: "Bitte akzeptieren Sie die Datenschutzerklärung!",
                        displayInfoCondition: false
                    })
                }
            }
        }

        const resetState = () => {
            this.setState(this.baseState)
        }
        
        const handleFormSubmit = (event) => {
            
            event.preventDefault()
            if(checkAll()){
                let payload = 
                { 
                    name: this.state.formName,
                    email: this.state.formEmail,
                    regard: this.state.formRegard,
                    message: this.state.formMessage,
                }

                 /*Alle boxen müssen geleert werden nach erfolgreichem Senden der Mail
                also auch checken ob die Nachricht erfolgreich gesendet wurde 404 etc.*/

                try {
                    /*CORS muss beachtet werden*/
                    let req = new XMLHttpRequest()
                    req.open('POST', 'https://my-express.vercel.app/send', false); // Hier auf send ändern
                    req.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
                    req.send(JSON.stringify(payload))
                    if(req.status === 204){
                        resetState()
                        
                        this.setState({
                            displayInfo: true,
                            displayInfoMessage: "Die Nachricht wurde erfolgreich versendet!",
                            displayInfoCondition: true
                        })
                    } else {
                        throw new Error(`${req.status} - E-Mail konnte nicht gesendet werden. Versuche es später erneut.`)
                    }
                } catch (error) {
                    this.setState({
                        displayInfo: true,
                        displayInfoMessage: error.message,
                        displayInfoCondition: false
                    })
                }                  
            }
        }

        const handleDataAcceptance = () => {
            this.setState({
                acceptDataSecurity : !this.state.acceptDataSecurity
            });
        }

        const handleFormEmailChange = (event) => {
            if(event.target.value.includes("@")){
                event.target.style.borderColor = "black"
                this.setState({
                    formEmail: event.target.value,
                    validEmail: true
                })
            } else {
                event.target.style.borderColor = "red"
                this.setState({
                    formEmail: event.target.value,
                    validEmail: false
                })
            }
        }
        
        const handleFormNameChange = (event) => {
            this.setState({
                formName: event.target.value
            })
        }

        const handleFormRegardChange = (event) => {
            this.setState({
                formRegard: event.target.value
            })
        }

        const handleFormMessageChange = (event) => {
            this.setState({
                formMessage: event.target.value
            })
        }

        return (
            <div className="contact-container">
                <div className="contact-left-side">
                    <h2 className="contact-info-heading">Kontaktieren Sie mich!</h2>
                    <div className="contact-info">
                        Bitte fühlen Sie sich frei mich jederzeit zu kontaktieren.
                        <br/>
                        Ich freue mich über jede Kontaktanfrage, ob Sie eine Frage zu einem meiner Projekte haben oder 
                        mir feedback zu meiner Webseite geben wollen. 
                        Egal welcher Grund Sie können mir jederzeit eine E-Mail schicken!
                    </div>
                </div>

            {this.state.displayInfo?
                    <InfoMessage message={this.state.displayInfoMessage} condition={this.state.displayInfoCondition} resetInfoBox={this.resetInfoBox}/>
                    : null
                }
                <div className="right-container">
                    <form className="contact-right-side">
                        <input className="input name" placeholder={"Vor- & Nachname"} value={this.state.formName} onChange={handleFormNameChange}></input> 
                        <input className="input email" placeholder={"E-Mail"} value={this.state.formEmail} onChange={handleFormEmailChange}></input>
                        <input className="input regard" placeholder={"Betreff"} value={this.state.formRegard} onChange={handleFormRegardChange}></input>
                        <textarea className="input message" placeholder={"Nachricht..."} cols="50" rows="10" value={this.state.formMessage} onChange={handleFormMessageChange}></textarea>
                        <div className="contact-datasecurity" onClick={handleDataAcceptance}>
                            {this.state.acceptDataSecurity? <>&#9745; </> : <>&#9744; </>}
                            Ich bin damit einverstanden per E-Mail kontaktiert zu werden und ich habe die 
                            <a className="datasecurity-link" href="/datasecurity"><b> Datenschutzerklärung </b></a>
                            gelesen und erkläre mich mit diesen einverstanden.
                        </div>
                        <button className="contact-submit" onClick={handleFormSubmit}>ABSENDEN</button>
                    </form>
                </div>    
            </div>
        )
    }
}

export default Contact
